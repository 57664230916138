.dp-input {
    height: 36px;
    border-radius: 4px;
    border-color: #f2f5fa;
    background-color: #f2f5fa;
}

.dp-input:hover {
    background-color: #F8F9FC;
    border-color: #F8F9FC;
    box-shadow: none;
}

/* .dp-input:focus-within {
    background-color: #FFF;
    border-color: #788FFF;
    box-shadow: none;
}

:global(.ant-form-item-has-error) .dp-input:focus-within {
    background-color: #FFF;
    border-color: #FF7875;
    box-shadow: none;
}

:global(.dp-input .ant-input) {
    background-color: #f2f5fa;
    border-color: #f2f5fa;
}

:global(.dp-input .ant-input):hover {
    background-color: #F8F9FC;
    border-color: #F8F9FC;
}

:global(.dp-input .ant-input):focus {
    background-color: #FFF;
}

:global(.ant-form-item-has-error) .dp-input:focus {
    box-shadow: none;
    border-color: #FF7875!important;
}

:global(.ant-form-item-has-error) .dp-input:hover {
    border-color: #FF7875;
}

:global(.ant-form-item-has-error .ant-input) {
    background-color: #FFF;
}

:global(.ant-form-item-has-error .ant-input):hover {
    background-color: #FFF;
}

:global(.ant-form-item-has-error .ant-form-item-explain-error) {
    margin-top: 8px;
    font-size: 10px;
    color: #E65350;
    line-height: 14px;
}

:global(.ant-form-item-has-error .ant-form-item-explain) {
    margin-top: 8px;
    font-size: 10px;
    color: #E65350;
    line-height: 14px;
    min-height: auto;
} */