.filter-container {
    padding: 8px;
}

.margin-b-10 {
    margin-bottom: 10px;
}

div.check-item {
    display: flex;
    flex-direction: column;
}

div.check-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 15px;
}