body {
    margin: 0;
    font-family: PingFang SC, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.gordon-layout {
    height: calc(100vh - 64px);
}

.gordon-main-container {
    padding: 10px;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Safari */
    margin: 0;
}
.admin-iframe {
    width: 100%;
    height: 100%;
    border: none;
}
