.detail {
  position: relative;
  padding: 10px 20px;
}

.back {
  margin-bottom: 10px;
}

form.form{
  width: 600px;
  border: 1px solid #999;
}

div.item {
  padding: 4px 0;
  margin-bottom: 0;
  border-bottom: 1px solid #999;
}
div.item:last-child {
  border-bottom: none;
}

.detail .buttons {
  margin: 0;
  padding: 10px 0;
  /* box-shadow: 0 0 4px #999; */
  box-shadow: 0px -5px 3px -2px rgb(191, 190, 190);
}

.content {
  padding-bottom: 10px;
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}

.mask {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.2);
}

.spin {
  position: relative;
  top: -10vh;
}