.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    color: #FFF;
}

.header-text {
    color: #FFF;
    font-weight: bold;
}