.form-container {
    margin-top: 20px;
}

.margin-b-10 {
    margin-bottom: 10px;
}

.error {
    color: #fe4348;
}