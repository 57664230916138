.login-page {
    display: flex;
    justify-content: space-between;
    background-color: #EFF6FF;
    height: 100vh;
    position: relative;
}

.text-container {
    position: relative;
    margin: 64px 0 0 72px;
    z-index: 9;
}

.slogan {
    font-size: 52px;
    font-family: PingFangSC-Semibold, PingFang SC;
    line-height: 72px;
    color: #1B1D40;
    margin-bottom: 8px;
}

.title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    line-height: 28px;
    color: #555878;
    margin-bottom: 20px;
}

.desc {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    line-height: 16px;
    color: #B6BCD2;
    width: 562px;
    margin-bottom: 16px;
}

.rectangle {
    width: 95px;
    height: 4px;
    background-color: #3945E6;
}

.login-container {
    display: flex;
    height: 750px;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.left-container {
    flex-grow: 1;
    position: relative;
}

.background-img {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.right-container {
    height: 100%;
    padding: 0 40px 0;
    background-color: #FFF;
    border-radius: 8px;
}

.logo-container {
    display: flex;
    align-items: center;
    color: #333;
    font-weight: 600;
    margin-bottom: 60px;
}

.logo-img {
    width: 140px;
    height: 35px;
}

.footer {
    display: flex;
    justify-content: center;
    position:absolute;
    left: 70px;
    bottom: 40px;
    font-size: 12px;
    line-height: 16px;
    color: #A5A7BD;
    font-family: PingFangSC-Medium, PingFang SC;
}

.footer-item {
    margin-right: 20px;
}

.footer-item-link {
    /* margin-right: 20px; */
    /* color: #BBB; */
    composes: footer-item;
    color: #A5A7BD;
    cursor: pointer;
}

.footer-item-link:hover {
    color: #555878;
}