.login-form-container  {
    margin-top: 160px;
}

.form-content {
    width: 320px;
}

.form-item {
    margin-bottom: 12px;
}

.error-tips {
    color: #f5222D;
    font-size: 10px;
    margin-left: 7px;
}

button.login-btn {
    width: 320px;
    font-size: 16px;
    line-height: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    background: linear-gradient(90deg, #a68eff 0%, #3c49dd 100%);
}

button.login-btn:hover {
    color: #FFF;
    background: linear-gradient(90deg, #B7A4FF 0%, #636DE3 100%);
}

button.login-btn:focus {
    color: #FFF;
    background: linear-gradient(90deg, #a68eff 0%, #3c49dd 100%);
}

button.login-btn:active {
    color: #FFF;
    background: linear-gradient(90deg, #8471CC 0%, #303AB0 100%);
}

:global(.login-wrapper .ant-form-item) {
    margin-bottom: 16px;
}

button.dp-btn {
    font-family: PingFangSC-Regular, PingFang SC;
    border-radius: 4px;
    padding: 7px 10px;
    height: auto;
    background: #3C3FED;
    color: #fff;
    border: none;
}

button.dp-btn:hover {
    color: #FFF;
    background: #5256F5;
}

button.dp-btn:focus {
    color: #FFF;
    background: #3C3FED;
}

button.dp-btn:active {
    color: #FFF;
    background: #2527E4;
}